<template>
  <div class="bo_head" :data-bo="$route.meta.bo" :data-bo-config="$route.meta.bo_config">
    <div class="left">
      <router-link tag="div" :to="{ name: 'Home' }" class="marque"><span class="">MonResto</span><span class="click">.click</span></router-link>
      <div class="boutique_name" v-if="boutique">{{ boutique.name }}</div>
    </div>

    <div class="right" v-if="$store.state.boutique_bo.boutique.boutique">
      <el-tooltip content="Voir mon restaurant">
        <div @click="view_boutique" class="ico_config no_background" v-if="$route.meta.bo === true">
          <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="24" height="24" viewBox="0 0 24 24"><path d="M12,18H6V14H12M21,14V12L20,7H4L3,12V14H4V20H14V14H18V20H20V14M20,4H4V6H20V4Z" /></svg>
        </div>
      </el-tooltip>
      <el-tooltip content="Configuration" v-if="!$route.meta.bo_config">
        <router-link tag="div" :to="{ name: 'bo_config_boutique' }" class="ico_config" :data-notif="!$store.state.boutique_bo.boutique.boutique.can_online" v-if="$route.meta.bo === true">
          <svg id="btn_config" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="24" height="24" viewBox="0 0 24 24"><path d="M12,15.5A3.5,3.5 0 0,1 8.5,12A3.5,3.5 0 0,1 12,8.5A3.5,3.5 0 0,1 15.5,12A3.5,3.5 0 0,1 12,15.5M19.43,12.97C19.47,12.65 19.5,12.33 19.5,12C19.5,11.67 19.47,11.34 19.43,11L21.54,9.37C21.73,9.22 21.78,8.95 21.66,8.73L19.66,5.27C19.54,5.05 19.27,4.96 19.05,5.05L16.56,6.05C16.04,5.66 15.5,5.32 14.87,5.07L14.5,2.42C14.46,2.18 14.25,2 14,2H10C9.75,2 9.54,2.18 9.5,2.42L9.13,5.07C8.5,5.32 7.96,5.66 7.44,6.05L4.95,5.05C4.73,4.96 4.46,5.05 4.34,5.27L2.34,8.73C2.21,8.95 2.27,9.22 2.46,9.37L4.57,11C4.53,11.34 4.5,11.67 4.5,12C4.5,12.33 4.53,12.65 4.57,12.97L2.46,14.63C2.27,14.78 2.21,15.05 2.34,15.27L4.34,18.73C4.46,18.95 4.73,19.03 4.95,18.95L7.44,17.94C7.96,18.34 8.5,18.68 9.13,18.93L9.5,21.58C9.54,21.82 9.75,22 10,22H14C14.25,22 14.46,21.82 14.5,21.58L14.87,18.93C15.5,18.67 16.04,18.34 16.56,17.94L19.05,18.95C19.27,19.03 19.54,18.95 19.66,18.73L21.66,15.27C21.78,15.05 21.73,14.78 21.54,14.63L19.43,12.97Z" /></svg>
        </router-link>
      </el-tooltip>
      <el-tooltip content="Retour" v-else>
        <router-link tag="div" :to="{ name: 'bo_commandes_enattente' }" class="ico_config" v-if="$route.meta.bo === true">
          <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="24" height="24" viewBox="0 0 24 24"><path d="M20,10V14H11L14.5,17.5L12.08,19.92L4.16,12L12.08,4.08L14.5,6.5L11,10H20Z" /></svg>
        </router-link>
      </el-tooltip>
    </div>

  </div>
</template>

<script>
export default {
  name: "h_bo",
  props: [ 'boutique' ],
  methods: {
    view_boutique() {
      this.$store.dispatch("boutique/sync_force", this.$store.state.user.user.boutique);
      this.$router.push({ name: 'boutique_id', params: { boutique_id: this.$store.state.user.user.boutique } })
    }
  }
}
</script>

<style scoped>

.bo_head { height: 70px; position: fixed; top: 0; right: 0; left: 0; background: #fff; z-index: 60; display: flex; align-items: center; justify-content: space-between; padding: 0 16px;
  
}
.bo_head .left .marque { font-size: 18px }
.bo_head .left .boutique_name { font-size: 14px; font-weight: 400; padding-top: 02px; }
.bo_head .right { display: flex; }
.bo_head .right div { margin-left: 10px; }


.ico_config { background: #eee; width: 44px; height: 44px; display: flex; align-items: center; justify-content: center; border-radius: 100%; cursor: pointer; position: relative; }
.ico_config[data-notif]:after { content: ''; position: absolute; right: -1px; top: -1px; width: 14px; height: 14px; border-radius: 100%; background: var(--boRed) }
.ico_config svg { position: relative; top: -0.5px }
.ico_config.no_background { background: none; fill: var(--boColor-gris1) }


.bo_head[data-bo][data-bo-config] { box-shadow: 0 2px 6px 0 rgba(0,0,0,.12), inset 0 -1px 0 0 #dadce0; }
.bo_head[data-bo]:not([data-bo-config]) { background: #1e2023!important; color: #c5c5c5 }
.bo_head[data-bo]:not([data-bo-config]) .ico_config { fill: #c5c5c5!important; }
.bo_head[data-bo]:not([data-bo-config]) .ico_config:not(.no_background) { background: #36393f }

@media only screen and (min-width: 800px) {
  .bo_head .left .boutique_name { font-size: 15px;  }
}
</style>