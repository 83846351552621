<template>
  <div class="alert">

    <lottie-animation class="ico" :width="200" :height="200" path="lottie/notification.json" />
    
    <div class="txt">Nouvelle Commande</div>
  </div>
</template>

<script>
import LottieAnimation from "lottie-vuejs/src/LottieAnimation";

export default {
  name: "alert_command",
  data() {
    return {
      audio: {}  
    }
  },
  components: {
    LottieAnimation
  },
  created() {
    this.audio["new_order"] = new Audio("/audio/new_order.mp3");
    this.audio["new_order"].loop = true;
    this.audio["new_order"].play()
  },
  destroyed() {
    this.audio["new_order"].pause()
  }
}
</script>

<style scoped>
  .alert { position: fixed; top: 0; right: 0; bottom: 0; left: 0; background: #fff; z-index: 100; display: flex; align-items: center; justify-content: center; flex-direction: column;
    background-color: #FBAB7E;
    background-image: linear-gradient(62deg, #FBAB7E 0%, #F7CE68 100%);
  }
  .alert .txt { margin-top: 16px; }

</style>