<template>
  <div>
    <h_bo :boutique="boutique"></h_bo>

    <div v-if="!online" class="alertNetwork"><span>Serveur introuvable</span>Essayez de recharger la page</div>


    <charte_qualite v-if="boutique && !boutique.quality_check" @success="quality_check_success"></charte_qualite>

    <alert_command v-if="alert_command_etat" @click.native="stop_alert_command_etat"></alert_command>

    <div class="online" v-if="boutique && boutique.active" :data-online="boutique.online">
      <div class="online_cont" @click="online_change">
        <div class="t"></div>
        <el-switch @change="online_change"
                   v-model="boutique.online"
                   active-color="#598613"
                   inactive-color="#a02323">
        </el-switch>
      </div>


      <el-dropdown trigger="click" @command="delay_edit" class="delay">

        <div class="delay_int">
          <i class="el-icon-time"></i>
          {{ boutique.delay }} minutes
          <!--          {{ $delayFormat(boutique.delay) }}-->
          <i class="arrow el-icon-arrow-up"></i>
        </div>

        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="" disabled>Délai livraison moyen</el-dropdown-item>
          <el-dropdown-item command="20" divided icon="el-icon-time">20 minutes</el-dropdown-item>
          <el-dropdown-item command="30" icon="el-icon-time">30 minutes</el-dropdown-item>
          <el-dropdown-item command="40" icon="el-icon-time">40 minutes</el-dropdown-item>
          <el-dropdown-item command="50" icon="el-icon-time">50 minutes</el-dropdown-item>
          <el-dropdown-item command="60" icon="el-icon-time">1 heure</el-dropdown-item>
          <el-dropdown-item command="70" icon="el-icon-time">1 heure 10</el-dropdown-item>
          <el-dropdown-item command="80" icon="el-icon-time">1 heure 20</el-dropdown-item>
          <el-dropdown-item command="90" icon="el-icon-time">1 heure 30</el-dropdown-item>
          <el-dropdown-item command="100" icon="el-icon-time">1 heure 40</el-dropdown-item>
          <el-dropdown-item command="110" icon="el-icon-time">1 heure 50</el-dropdown-item>
          <el-dropdown-item command="120" icon="el-icon-time">2 heures</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    
  <div class="bo" v-loading.fullscreen.lock="loading" v-if="boutique" :data-online="boutique.online" :data-active="boutique.active">

    
    <router-link v-if="boutique && !boutique.active" tag="div" :to="{ name: 'bo_config_boutique' }" class="bar red">
      <i class="el-icon-warning-outline"></i> 
      Votre restaurant n'est pas en ligne, cliquez pour terminer la configuration.
    </router-link>


    
    <nav v-if="boutique" :data-active="boutique.active">
<!--      <div class="elem">b</div>-->
      
      <div class="commandes">
        
        <router-link id="btn_enattente" tag="div" :to="{ name: 'bo_commandes_enattente' }" class="elem" :class="{ active: this.$route.name === 'bo_commandes_enattente' }">
          <div class="nb" v-if="commandes && commandes.waiting && commandes['waiting'].length">{{ commandes['waiting'].length }}</div>
          <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="28" height="28" viewBox="0 0 24 24"><path d="M12,13A5,5 0 0,1 7,8H9A3,3 0 0,0 12,11A3,3 0 0,0 15,8H17A5,5 0 0,1 12,13M12,3A3,3 0 0,1 15,6H9A3,3 0 0,1 12,3M19,6H17A5,5 0 0,0 12,1A5,5 0 0,0 7,6H5C3.89,6 3,6.89 3,8V20A2,2 0 0,0 5,22H19A2,2 0 0,0 21,20V8C21,6.89 20.1,6 19,6Z" /></svg>
        </router-link>
        
        <router-link id="btn_processing" tag="div" :to="{ name: 'bo_commandes_encours' }" class="elem processing" :class="{ active: this.$route.name === 'bo_commandes_encours' }">
          <div class="nb" v-if="commandes && commandes.processing && commandes['processing'].length">{{ commandes['processing'].length }}</div>
<!--     hisotry     <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="28" height="28" viewBox="0 0 24 24"><path d="M16.5 15.82L18.94 17.23L18.19 18.53L15 16.69V13H16.5V15.82M20 10.26V5C20 3.9 19.11 3 18 3H4C2.9 3 2 3.9 2 5V17C2 18.11 2.9 19 4 19H9.68C10.81 21.36 13.21 23 16 23C19.87 23 23 19.87 23 16C23 13.62 21.81 11.53 20 10.26M18 7V9.29C17.37 9.11 16.7 9 16 9C14.5 9 13.13 9.47 12 10.26V7H18M4 7H10V11H4V7M4 17V13H9.68C9.25 13.91 9 14.93 9 16C9 16.34 9.03 16.67 9.08 17H4M16 21C13.24 21 11 18.76 11 16S13.24 11 16 11 21 13.24 21 16 18.76 21 16 21Z" /></svg>-->
          <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="28" height="28" viewBox="0 0 24 24"><path d="M21,16.5C21,16.88 20.79,17.21 20.47,17.38L12.57,21.82C12.41,21.94 12.21,22 12,22C11.79,22 11.59,21.94 11.43,21.82L3.53,17.38C3.21,17.21 3,16.88 3,16.5V7.5C3,7.12 3.21,6.79 3.53,6.62L11.43,2.18C11.59,2.06 11.79,2 12,2C12.21,2 12.41,2.06 12.57,2.18L20.47,6.62C20.79,6.79 21,7.12 21,7.5V16.5M12,4.15L6.04,7.5L12,10.85L17.96,7.5L12,4.15Z" /></svg>
        </router-link>

        <router-link id="btn_sent" tag="div" :to="{ name: 'bo_commandes_aexpedier' }" class="elem sent" :class="{ active: this.$route.name === 'bo_commandes_aexpedier' }">
          <div class="nb" v-if="commandes && commandes.sent && commandes['sent'].length">{{ commandes['sent'].length }}</div>
          <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="24" height="24" viewBox="0 0 24 24"><path d="M11.74 13.36L14.14 7.71L13.06 5.5H10.5V4H14L14.73 5.5H21.75L20.75 9H16.44L17.11 10.37C17.69 10.13 18.33 10 19 10C21.76 10 24 12.24 24 15C24 17.76 21.76 20 19 20C16.24 20 14 17.76 14 15C14 13.45 14.71 12.06 15.82 11.15L15 9.5L12.25 16H9.9C9.44 18.28 7.42 20 5 20C2.24 20 0 17.76 0 15C0 12.24 2.24 10 5 10C7.59 10 9.72 11.97 10 14.5H10.58L8.3 9H7.5C7.09 9 6.75 8.66 6.75 8.25C6.75 7.84 7.09 7.5 7.5 7.5H10.25C10.66 7.5 11 7.84 11 8.25C11 8.66 10.66 9 10.25 9H9.97L11.74 13.36M5 11.5C3.07 11.5 1.5 13.07 1.5 15C1.5 16.93 3.07 18.5 5 18.5C6.59 18.5 7.93 17.45 8.36 16H4V14.5H8.47C8.22 12.8 6.76 11.5 5 11.5M19 11.5C18.57 11.5 18.15 11.58 17.77 11.72L19.7 15.68L18.35 16.34L16.5 12.55C15.88 13.18 15.5 14.05 15.5 15C15.5 16.93 17.07 18.5 19 18.5C20.93 18.5 22.5 16.93 22.5 15C22.5 13.07 20.93 11.5 19 11.5Z" /></svg>
        </router-link>
      </div>

      <div class="commandes">
      <router-link id="btn_produits" tag="div" :to="{ name: 'bo_produits' }" class="elem" :class="{ active: this.$route.name === 'bo_produits' }">
        <div class="nb" v-if="this.alert_stock">{{ this.alert_stock }}</div>
        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="24" height="24" viewBox="0 0 24 24"><path d="M2,16H22V18C22,19.11 21.11,20 20,20H4C2.89,20 2,19.11 2,18V16M6,4H18C20.22,4 22,5.78 22,8V10H2V8C2,5.78 3.78,4 6,4M4,11H15L17,13L19,11H20C21.11,11 22,11.89 22,13C22,14.11 21.11,15 20,15H4C2.89,15 2,14.11 2,13C2,11.89 2.89,11 4,11Z" /></svg>
      </router-link>
      <router-link id="btn_stats" tag="div" :to="{ name: 'bo_stats' }" class="elem" :class="{ active: this.$route.name === 'bo_stats' }">
        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="28" height="28" viewBox="0 0 24 24"><path d="M4 19V20H22V22H2V2H4V17C7 17 10 15 12.1 11.4C15.1 6.4 18.4 4 22 4V6C19.2 6 16.5 8.1 13.9 12.5C11.3 16.6 7.7 19 4 19Z" /></svg>
      </router-link>
      </div>
    </nav>

    <transition name="slide2-fade">
      <router-view></router-view>
    </transition>


<!--    <v-tour name="myTour" :steps="steps" :options="myOptions"></v-tour>-->
    

  </div>
  </div>
</template>

<script>
import Vuex from 'vuex';
import alert_command from '@/components/bo/alert_command';
import charte_qualite from '@/components/global/charte_qualite';

import NoSleep from "nosleep.js";
let noSleep = new NoSleep();

import h_bo from "@/components/bo/h_bo";

export default {
  name: "index",
  data() {
    return {
      // commandes: {},
      // boutique: null,
      online: true,
      
      loading: false,
      alert_command_etat: false,
      myOptions: {
        useKeyboardNavigation: false,
        labels: {
          buttonSkip: 'Passer la visite',
          buttonPrevious: 'Précedent',
          buttonNext: 'Suivant',
          buttonStop: 'Terminer'
        }
      },
      steps: [
        {
          target: '#btn_enattente',  // We're using document.querySelector() under the hood
          content: `Lors du passage d'une commande par l'un de vos clients, une fois le paiement validé, vous devrez confirmer la commande sur cette page.`
        },
        {
          target: '#btn_processing',  // We're using document.querySelector() under the hood
          content: `Vos commandes à préparer apparaîtrons ici, une fois prête, vous n'avez plus qu'a confirmer. Le client est automatiquement informé.`
        },
        {
          target: '#btn_sent',  // We're using document.querySelector() under the hood
          content: `Vos commandes à expédier ou à retirer apparaîtrons ici.`
        },
        {
          target: '#btn_produits',  // We're using document.querySelector() under the hood
          content: `Texte gestion produits.`
        },
        {
          target: '#btn_stats',  // We're using document.querySelector() under the hood
          content: `Texte statistiques.`
        },
        {
          target: '#btn_config',  // We're using document.querySelector() under the hood
          content: `Texte configuration.`
        }
      ]
    }
  },
  components: {
    alert_command,
    h_bo,
    charte_qualite
  },
  watch: {
    getUser() {
      if(this.$store.state.user.user.boutique) {
        console.log('user init')

        this.$socket.client.emit('join', 'boutique_bo_' + this.$store.state.user.user.boutique)
      
        this.$store.dispatch("boutique_bo/sync");
        this.$store.dispatch("boutique_bo/sync_commandes");
      }
    }
  },
  computed: {
    ...Vuex.mapState({
      getUser: state => state.user.user,
      boutique: state => state.boutique_bo.boutique.boutique,
      commandes: state => state.boutique_bo.commandes
    }),
    ...Vuex.mapGetters({
      alert_stock: 'boutique_bo/alert_stock'
    })
  },
  created() {

    console.log('user', this.$store.state.user.user.boutique)
    if(this.$store.state.user.user.boutique) {
      this.$socket.client.emit('join', 'boutique_bo_' + this.$store.state.user.user.boutique)
      this.$store.dispatch("boutique_bo/sync");
      this.$store.dispatch("boutique_bo/sync_commandes");
    }
  },
  mounted() {
    
    console.log(noSleep)
    document.addEventListener('click', function enableNoSleep() {
      // document.removeEventListener('click', enableNoSleep, false);
      if(!noSleep.enabled)
        noSleep.enable();
      
    }, false);
  },
  sockets: {
    connect() {
      if(this.$store.state.user.user.boutique) {
        console.log('connect', this.$store.state.user.user.boutique)
        this.online = true
        this.$socket.client.emit('join', 'boutique_bo_' + this.$store.state.user.user.boutique)
        this.$store.dispatch("boutique_bo/sync");
        this.$store.dispatch("boutique_bo/sync_commandes");
      }
    },
    disconnect() {
      this.online = false
      // alert('disconnected')
    },
    refresh() {
      this.$store.dispatch("boutique_bo/sync");
      this.$store.dispatch("boutique_bo/sync_commandes");
    },
    alert_command() {
      this.alert_command_etat = true
    }
  },
  methods: {
    stop_alert_command_etat() {
      this.alert_command_etat = false
      if(this.$route.name !== 'bo_commandes_enattente')
        this.$router.push({ name: 'bo_commandes_enattente' })
    },
    quality_check_success() {
      this.boutique.quality_check = true
      this.refresh();
    },
    refresh() {
      this.$store.dispatch("boutique_bo/sync");
    },
    online_change() {
      this.boutique.online = !this.boutique.online
      console.log('online_change', this.$store.state.boutique_bo.online)
      this.$http.post('/bo/boutique/edit', {
        key: 'online',
        value: this.boutique.online
      }).then(() => {
        this.$store.dispatch("boutique_bo/sync");
      })
    },
    delay_edit(delay) {
      this.boutique.delay = delay
      this.$http.post('/bo/boutique/edit', {
        key: 'delay',
        value: this.boutique.delay
      }).then(() => {
        this.$store.dispatch("boutique_bo/sync");
      })
    }
  }
}
</script>

<style scoped>

  .alertNetwork { position: fixed; top: 0; right: 0; bottom: 0; left: 0; display: flex; align-items: center; justify-content: center; background: rgba(20,20,20,.9); z-index: 400; color: #fff; 
    border: 8px solid var(--boRed); font-size: 22px; line-height: 30px; text-align: center; flex-direction: column;
  }
  .alertNetwork span { font-size: 26px; font-weight: 900; text-transform: uppercase; color: var(--boRed) }


  .online { display: flex; align-items: center; justify-content: flex-start; background: #fff;
    font-size: 14px; font-weight: 500; background: var(--boRed); color: #fff; cursor: pointer; min-width: calc(100% - 108px);
    position: fixed; left: 0; top: 0; height: 70px; padding: 10px 16px; background: #202225; z-index: 64;
    
  }
  .online .t { padding-right: 0px; padding-bottom: 6px; font-weight: 400; color: #c5c5c5 }
  .online .t:before { content: 'En ligne' }
  
  .online .online_cont { display: flex; flex-direction: column; justify-content: center; align-items: center; }
  
  .online .delay { background: #36393f; color: var(--white-main); margin-left: 24px; height: 100%; border-radius: 6px; flex: 0 0 auto }
  .online .delay .delay_int { height: 100%; display: flex; align-items: center; justify-content: center; outline: none; padding: 0 12px 0 16px; }
  .online .delay i:first-child { margin-right: 6px; }
  .online .delay i.arrow { transform: rotate(180deg); margin-left: 7px; color: var(--white-sub) }
  
  /*.online[data-online=true] { background: var(--boVert) }*/


  @media only screen and (min-width: 800px) {
    .online { position: fixed; left: 50%; height: 70px; transform: translateX(-50%); min-width: inherit; justify-content: space-between; }
    .online .online_cont { width: 300px; flex-direction: row; justify-content: space-between; padding: 0 16px; height: 100%; /*border: 1px solid #545b65; background: none!important; */
      border-radius: 6px; background: #500f0f; transition: background-color .1s }
    .online[data-online] .online_cont { background: #185621; }
    .online .online_cont .t { padding-right: 16px; padding-bottom: 0; }
    .online .online_cont .t:before { content: 'Accepter les commandes' }
    .online .delay { margin-left: 12px; }
  }
</style>

<style>


  .bo button { width: 200px; margin-top: 32px; border-radius: 6px; background: var(--orange); color: #fff; display: block }
  .bo button[disabled] { background: var(--gris); cursor: not-allowed; }

  .under_construct { display: inline-flex; align-items: center; color: var(--white-main); padding: 8px 16px; background: var(--black-list);
    margin-bottom: 16px; font-size: 18px; font-weight: 100;
  }
  .under_construct svg { fill: var(--white-main); margin-right: 16px; }

  .bo .el-form-item__label { color: #ddd }
  .bo_config .el-form-item__label { text-align: left }
  .bo .step .el-form-item__label { color: var(--black-main) }
  .bo .options .el-form-item__label { color: var(--black-main) }

  .bo { position: fixed; top: 70px; right: 0; bottom: 0; left: 0; z-index: 60; overflow-y: auto; background: #1d1f25; border-top: 4px solid var(--boRed); }
  .bo[data-online=true] { border-top-color: var(--vert-button) }



  .bo nav { position: fixed; left: 0; right: 0; bottom: 0; display: flex; flex-direction: row; justify-content: space-between; background: #1d1f25; padding: 20px; z-index: 50;
    /*box-shadow: 0 -5px 6px rgba(180,180,180,.5);*/
    box-shadow: 0 -2px 16px rgb(21 21 21 / 50%)
  }
  .bo .elem { background: #373e50; width: 60px; min-height: 60px; max-height: 60px; position: relative; display: flex; align-items: center; justify-content: center; color: #fff; border-radius: 4px }
  .bo .elem svg { fill: #fff }
  .bo .elem.active { background: #ea8b4a }
  .bo .elem.active svg { fill: #fff }
  .bo .elem .nb { position: absolute; top: 0; right: 0px; width: 20px; height: 20px; background: #ff5f5f; display: flex; align-items: center; justify-content: center; font-size: 14px;
    border-radius: 0 0 0 2px;
     }
  .bo .elem.processing .nb { background: var(--boTurq) }
  .bo .elem.sent .nb { background: var(--boVert) }


  .bo nav .commandes { height: 100%; border-radius: 4px; overflow: hidden; display: flex; }
  .bo nav .commandes .elem { border-right: 1px solid #272d3c; border-radius: 0 }
  .bo nav .commandes .elem:last-child { border: none; }


  .bo .info_ico { font-size: 16px; display: flex; align-items: center; padding: 6px 0 16px 16px; font-weight: 400; color: var(--white-sub) }
  .bo .info_ico i { margin-right: 6px; font-size: 16px; position: relative; bottom: 2px; }
  
  
  .bo .form_simple .el-form { padding: 0 var(--padding) }

  .bo .commande { margin-bottom: 20px!important; }
  
  .bo .tuto { display: flex; align-items: center; flex-direction: column; text-align: center; padding: 0 16px; justify-content: center; font-size: 18px; line-height: 28px; font-weight: 200;
    color: #c5c5c5;
  }
  
  .bo .commandes_cont { padding: 0 8px; padding-bottom: 120px; }

  .bo .scrollx .title { margin-top: 16px; padding: 0 14px; color: #c5c5c5 }
  .bo .title .int { color: #c5c5c5; font-weight: 400; }
  /*.bo .title { margin-top: 16px; padding: 0 14px }*/


  @media only screen and (min-width: 800px) {
    .bo .info_ico { padding-left: 0; }
    .bo .scrollx { margin-left: 150px; margin-top: 20px; position: relative;}
    .bo .scrollx .title { padding: 0 }
    .bo .commandes_list .commandes_cont { display: flex; flex-wrap: wrap; width: 100%; }
    .bo .commandes_list .commande { margin-right: 20px; width: calc(100% / 2 - 20px); flex: 0 0 auto; }

    /*.bo .scrollx { margin-left: 150px; margin-top: 20px; max-height: calc(100vh - 200px); position: relative;}*/
    /*.bo .commandes_list::-webkit-scrollbar { display: none }*/
    /*.bo .commandes_list { display: flex; overflow-x: auto; scroll-behavior: smooth; */
    /*  flex-direction: column;  height: calc(100vw - 140px); width: calc(100vh - 150px); */
    /*  transform: rotate(-90deg) translate3d(0, -100vh, 0); overflow-x: hidden; margin-left: 140px;*/
    /*  transform-origin: right top;*/
    /*} */
    
    /*.bo .commandes_list .commande { margin-right: 20px; flex: 0 0 auto; }*/
    /*.bo .commandes_list .commande { width: 500px; min-width: 500px;*/
    /*  */
    /*}*/
    /*.bo .commandes_cont { display: flex; flex-direction: row;*/
    /*  transform-origin: left top;*/
    /*  transform: rotate(90deg) translate3d(0, calc(-100vh + 160px), 0)*/
    /*}*/
    
    /*.bo .commandes_list .commande:nth-child(1n + 2) { margin-right: 0; }*/
    
    .bo .tuto { width: 600px; margin: auto; font-size: 1vw; line-height: 1.6vw; min-height: calc((var(--vh, 1vh) * 100) - 300px); }
    .bo .tuto span { padding: 0 36px }
  }

  @media only screen and (min-width: 1500px) {
    .bo .commandes_list .commande { width: calc(100% / 3 - 20px);  }
  }
  
  @media only screen and (min-width: 1900px) {
    .bo .commandes_list { display: flex; }
    .bo .commandes_list .commande { width: calc(100% / 4 - 20px);  }
    /*.bo .commandes_list .commande { width: calc((100% / 3) - 20px); margin-right: 20px; }*/
    .bo .commandes_list .commande:nth-child(1n + 2) { margin-right: 20px; }
  }
  
  @media only screen and (min-width: 800px) {

    /*.bo { margin-top: 70px; }*/

    .bo nav { bottom: 30%; top: 115px; width: 100px; right: inherit; flex-direction: column; justify-content: flex-start; box-shadow: none }
    .bo nav[data-active=true] { top: 75px!important; }
    .bo nav { position: fixed; }
    .bo nav .commandes { flex-direction: column; height: inherit; margin-bottom: 20px; flex: 0 0 auto }
    .bo .page { padding-left: 150px!important; padding-right: var(--padding)!important; }

    .bo nav .commandes .elem { border-bottom: 1px solid #272d3c; border-right: none; height: calc(100% / 3); margin: 0 0 Z2Z0px 0; }
    
    .form_simple { max-width: 800px; }
    .bo .form_simple button { width: inherit }
    .bo .form_simple .el-form-item { width: 100%; }
    .bo .form_simple .el-form { padding: 0; display: flex; flex-direction: column; align-items: flex-end  }
  }
  @media only screen and (min-width: 1900px) {

    .bo .page.adjust_width { width: 60%; }

  }
</style>