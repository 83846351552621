<template>
  <div class="charte">
    <div class="b2">
      
    <div class="bloc">
      <div class="top">
        <div class="marque"><span>MonResto</span><span class="click">.click</span></div>
        
        <div class="title">Engagement Qualité</div>

      </div>
      <div class="col_grp">
         <div class="col">
           <div class="t">Proximité, saisonnalité, traçabilité et durabilité</div>
            
           <div class="e">Respecter et maîtriser les exigences réglementaires en matière d’hygiène et de sécurité alimentaire.</div>
           <div class="e">Tenir compte de la saisonnalité des produits, privilégier les produits frais, le fait maison et l’artisanat.</div>
           <div class="e">Privilégier les producteurs et artisans locaux.</div>
           <div class="e">Favoriser l’achat de produits sous signes officiels de qualité et d’exploitation en agriculture durable.</div>
           <div class="e">Privilégier les matières premières ne contenant pas d’OGM.</div>
         </div>
        
        <div class="col">
          <div class="t">Qualité de service</div>
          <div class="e">Limiter l’attente, aménager si possible une file d’attente spécifique pour la vente à emporter.</div>
          <div class="e">Fournir un contact de qualité et un accueil courtois lors de la remise des plats.</div>
          <div class="e">Soigner le dressage et la présentation.</div>
          <div class="e">Recueillir et tenir compte des avis clients dans un souci d’amélioration continue.</div>
          <div class="e">S’engager dans une démarche favorisant la préservation de l’environnement, (emballages, transport, recyclage des contenants…).</div>
        </div>
      </div>
      
      <div v-if="!view" class="bottom">
        <button @click="accept">Accepter</button>
      </div>

    </div>
      
    </div>
    
  </div>
</template>

<script>
export default {
  name: "charte_qualite",
  props: [ 'view' ],
  methods: {
    accept() {
      this.$http.post('/bo/boutique/edit', {
        id: this.$store.state.user.user.boutique,
        key: 'quality_check',
        value: true
      }).then(() => {
        this.$emit('success')
      })
    }
  }
}
</script>

<style scoped>
  .charte { position: fixed; top: 0; right: 0; bottom: 0; left: 0; background: rgba(30,30,30,.88); z-index: 1500; }
  .charte .b2 { height: 100%; }
  .charte .bloc { background: #fff; width: 100%; height: 100%; padding: 26px 36px; overflow-y: auto }
  .charte .top { display: flex; justify-content: space-between; flex-direction: column; margin-bottom: 28px; align-items: center }
  .charte .title { font-weight: bold; font-size: 18px }

  .col_grp { font-size: 18px }
  .col_grp .col { margin-bottom: 26px; }
  .col_grp .col .t { font-weight: 600; margin-bottom: 16px; }
  .col_grp .col .e { font-weight: 400; margin-bottom: 8px; font-size: 15px; position: relative; padding-left: 26px; }
  .col_grp .col .e:before { content: ''; background: linear-gradient(40deg, rgb(242, 63, 63), rgb(251, 127, 42)) 0px 0px no-repeat padding-box padding-box transparent; width: 15px; height: 15px; 
    border: 2px solid #fff; box-shadow: rgba(33, 33, 33, 0.2) 0px 0px 6px 0px;
    border-radius: 50%; position: absolute; top: 0; left: 0; }
  
  .marque { font-family: "Prompt"; font-size: 24px; font-weight: 600; }
  .marque .click { color: #ff7600; }
  
  .bottom button { background: linear-gradient(40deg, rgb(242, 63, 63), rgb(251, 127, 42)) 0px 0px no-repeat padding-box padding-box transparent; }


  @media only screen and (min-width: 800px) {
    .charte { }
    .charte .b2 { overflow-y: auto; height: 100%; display: flex; align-items: center; justify-content: center; width: 100%; }
    .charte .b2 .bloc { width: 700px; height: auto; border-radius: 8px }
    
  }
  @media only screen and (max-height: 600px) {
    .charte .b2 .bloc { height: 100%; }
  }
</style>